<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.name"
                  placeholder="会员昵称/手机号/编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">会员类型 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.type"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="普通用户"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="销售合伙人"
                  ></el-option>
                  <el-option
                    :value="3"
                    label="工作人员"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">会员等级 : </span>
              <div class="width200">
                <el-select
                  v-model="searchData.levelId"
                  placeholder="请选择"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    v-for="item in levelList"
                    :key="item.id"
                    :label="item.levelName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">账户状态 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option
                    value=""
                    label="请选择"
                  ></el-option>
                  <el-option
                    :value="1"
                    label="启用"
                  ></el-option>
                  <el-option
                    :value="2"
                    label="禁用"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
              >查询</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
              >重置</el-button>
            </div>
            <!-- 
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                style="background: #f59a23;color: #ffffff;border-color: #f59a23"
                icon="el-icon-upload2"
                @click="handleImport"
              >导入车辆</el-button>
            </div> -->

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                icon="el-icon-download"
                style="background: #c280ff;color: #ffffff;border-color: #c280ff"
                @click="handleExport"
              >导出</el-button>
            </div>
          </div>
        </div>
      </template>
      <!-- 
      <template
        slot="state"
        slot-scope="{ row }"
      >
        <el-switch
          v-if="row.moveState !== 3"
          v-model="row.state"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event,row.id)"
        ></el-switch>
        <span v-else>{{ row.state === 1?'开':row.state === 2?'关':'' }}</span>
      </template> -->

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          @click="handleDetails(row)"
        >会员详情</el-button>

        <el-button
          type="text"
          size="mini"
          @click="handleState(row)"
        >{{ row.state===1?'禁用':row.state===2?'解除禁用':'' }}</el-button>

      </template>
    </avue-crud>

    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
  </div>
</template>

<script>
import { list, stateUpdateRegion, getLevelList } from "@/api/user/manage";
import Import from "@/components/import";
import qs from "qs";

export default {
  name: "index",
  components: {
    Import,
  },
  data() {
    return {
      levelList: [],
      searchData: {
        name: "",
        levelId: "",
        state: "",
        type: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "会员列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "会员编号",
            prop: "code",
          },
          {
            label: "会员头像",
            prop: "avatarUrl",
            type: "img",
          },
          {
            label: "会员昵称",
            prop: "nickname",
          },
          {
            label: "会员手机号",
            prop: "phone",
          },
          {
            label: "会员openid",
            prop: "openid",
          },
          {
            label: "注册时间",
            prop: "registerTime",
          },
          {
            label: "用户来源",
            prop: "memberSource",
            formatter: function (row, column, cellValue) {
              return row.memberSource === 1
                ? "自行注册"
                : row.memberSource === 2
                ? "销售合伙人裂变"
                : row.memberSource;
            },
          },

          {
            label: "会员类型",
            prop: "type",
            formatter: function (row, column, cellValue) {
              let typesName = "";
              if (row.type === 1 && row.isWorker !== 1) {
                typesName = "普通用户";
              } else if (row.type === 2) {
                if (row.isWorker === 1) {
                  typesName = "销售合伙人/工作人员";
                } else {
                  typesName = "销售合伙人";
                }
              } else if (row.isWorker === 1) {
                typesName = "工作人员";
              }

              return typesName;
            },
          },
          {
            label: "会员等级",
            prop: "levelName",
          },
          {
            label: "当前亲昵度",
            prop: "intimacy",
          },
          {
            label: "累计消费(元)",
            prop: "totalConsume",
          },
          {
            label: "累计退款(元)",
            prop: "totalRefund",
          },
          {
            label: "累计余额(元)",
            prop: "totalBalance",
          },
          {
            label: "可用余额(元)",
            prop: "availableBalance",
          },
          {
            label: "累计积分",
            prop: "totalIntegral",
          },
          {
            label: "可用积分",
            prop: "availableIntegral",
          },
          {
            label: "账户状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "启用"
                : row.state === 2
                ? "禁用"
                : row.state;
            },
          },
        ],
      },

      importShow: false,
      form: {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      },
      importAction: "/api/web/car/bind/template/import",
      importProblem: "/api/web/car/bind/template/error/export",
      importDownloadLink: "/api/web/car/bind/template/export",
    };
  },
  created() {
    this.getLevelList();
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    async getLevelList() {
      getLevelList().then((res) => {
        if (res.code === 200) {
          this.levelList = res.data;
        }
      });
    },
    salaryChange(e, t, max) {
      if (
        Number(this.searchData.minElectricity) >
          Number(this.searchData.maxElectricity) &&
        this.searchData.maxElectricity
      ) {
        this.searchData.minElectricity = "";
      }
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        name: "",
        levelId: "",
        state: "",
        type: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 批量导入
    handleImport() {
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      console.log("e111", e);
      this.onLoad();
    },
    // 导出
    handleExport() {
      // console.log('导出')
      window.open(
        `/api/web/user/manage/export?${qs.stringify(this.searchData)}`,
        "_self"
      );
    },

    // 区域详情
    handleDetails(row) {
      this.$router.push({
        path: "/user/manage/details",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 冻结
    handleState(row) {
      const state = row.state == 1 ? "禁用" : row.state == 2 ? "解除禁用" : "";
      this.$confirm(`确定${state}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        stateUpdateRegion({
          id: row.id,
          state: row.state == 1 ? 2 : 1,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success(`${state}成功!`);
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      };
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
  },
};
</script>

<style scoped>
</style>